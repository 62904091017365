.sportsSection {
    padding-top: 104px;
}

.sportsContainer {
    display: grid;
    grid-template-columns: repeat(3, auto);
    justify-content: center;
    gap: 16px;
    margin-top: 32px;
}

.sportsSection h3 {
    margin: 64px 0px;
    font-family: "Poppins", sans-serif;
    text-align: center;
    font-size: 45px;
    text-transform: uppercase;
}

.sportsContainer a {
    text-decoration: none;
    color: black;
}

.sportsContainer li {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    overflow: hidden;
    background: white;
    height: 112px;
    gap: 12px;
    padding: 24px 12px 24px 12px;
    border: 2px solid black;    
    border-radius: 40px;
}

.sportsContainer li p {
    font-family: 'Poppins', sans-serif;
    color: #727070;
    text-transform: uppercase;
    font-weight: 700;
    text-align: right;
}

.sportsContainer li div {
    max-width: 100px;
    height: 90px;
}

.sportsContainer li img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media (min-width: 1100px){
    .sportsContainer {
        grid-template-columns: repeat(3, 280px);
    }
}

@media (max-width: 1100px){
    .sportsContainer {
        grid-template-columns: repeat(3, 180px);
    }

    .sportsContainer li img {
        width: 100px;
        height: 100px;
    }
}

@media (max-width: 600px){
    .sportsContainer {
        grid-template-columns: repeat(2, 160px);
    }

    .sportsContainer li p {
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
    }
    

    .sportsContainer li img {
        width: 70px;
        height: 70px;
    }

}
