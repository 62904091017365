.sectionEvents {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 104px;
    padding-bottom: 32px;
}

.listPaysEvent {
    margin-bottom: 8px;
}

.listPaysEvent li {
    font-family: 'Poppins', sans-serif;
    justify-content: center;
    display: flex;
    list-style: none;
    align-items: center;
    gap: 16px;
    padding: 4px 16px;
    max-width: max-content;
    margin: 8px auto;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.listPaysEvent li img {
    height: 16px;
}

.listPaysEvent li:hover {
    cursor: pointer;
}

.listPaysEvent li p {
    text-align: left;
    min-width: 90px;
}


.sectionEvents article:nth-child(1) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 50vw;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.headerImageEvents{
    height: 230px;
}

.headerImageEvents img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 0% 60%;
}

.sectionEvents div:nth-child(2) {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 8px;

}

.sectionEvents div:nth-child(2) h4 {    
    margin-top: 16px;
    font-size: 24px;
}   


.sectionEvents div:nth-child(2) h4 img {    
    height: 18px;
    padding: 0px 8px;
} 


.sectionEvents div:nth-child(1) p:nth-child(3){
    margin-top: 8px;
}

.sectionEvents div:nth-child(3) h5{ 
    font-size: 17px;
    font-family: 'Poppins', sans-serif;
}

.currentPlayerFiche {
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.currentPlayerFiche div {
    width: 300px;
    height: 250px;
}

.currentPlayerFiche div img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 10px 10%;
}

.currentPaysPlayer {
    margin-top: 16px;
    text-align: center;
}

.currentPaysPlayer h5 {
    font-size: 16px;
    font-family: "Poppins";
    margin-bottom: 4px;
}

.listJoueursPaysEvent {
    display: grid;
    grid-template-columns: repeat(4, 190px);
    justify-content: center;
    gap: 16px;
    margin: 0px 16px 16px 16px;
}

.listJoueursPaysEvent li {
    margin-top: 4px;
    font-family: "Poppins", sans-serif;
    justify-content: left;
    list-style: none;
    border: 1px solid #1e1e1e;
    border-radius: 0% 0% 16px 16px;
}

.listJoueursPaysEvent li div:nth-child(1) {
    width: 100%;
    height: 180px;   
}

.listJoueursPaysEvent li div img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 0% 0%;
}

.listJoueursPaysEvent li div svg {
    height: 25px;
    width: 25px;
}

.listJoueursPaysEvent li p {
    padding: 8px;
}
