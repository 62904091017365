.homeSection {
    padding-top: 104px;
}

.homeSection img{
    width: 100%;
}

.image {
    position: relative;
    text-align: center;
    color: white;
}
.image h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    font: 1.2em "Poppins", serif;
    transform: translate(-50%, -50%);
    font-weight: bold;
    font-size: 7vh;
    -webkit-text-stroke: 1px black;
}

.choice{
    width: 100%; 
    height: 500px;
}

.circle{
    background: -webkit-linear-gradient(left top, crimson 0%, #f90 100%);
    width: 250px;
    height: 250px;
    border-radius: 1000px;
    padding: 10px;
    position: relative;
    left: 10%;
}

.circleContent{
    background: white;
    width: 230px;
    height: 230px;
    border-radius: 1000px;
    padding: 10px;
}

.circleContent h2{
    position: relative;
    top:40%;
    left: 16%;
}

.choice :nth-child(2n){
left: 50%;
}

.homeSection h3{
    font-size: 2em;
    text-align: center;
    color :blue;
}

.podium{
    width: 100%;
    height: 300px;
    display: flex;
}

.podium1,.podium2,.podium3{
    width: 100px;
    height: 100px;
    border: 2px solid black;
    margin-right: 40px;
} 
